import React from 'react'
import clsx from 'clsx'
import { whereId } from '@connections/utils'
import CheckoutInfoCard from '../../utils/CheckoutInfoCard'
import FlightEconomicsMobileDeprecated from './FlightEconomicsMobileDeprecated'
import FlightSearchPageResultCardCollectionMobile from './FlightSearchPageResultCardCollectionMobile'

const FlightSearchPageResultCardMobileDeprecated = ({
    mode,
    alerts,
    charge,
    hasAddon,
    baggageAllowed,
    onChangeAirRoute,
    selectedAirRoutes,
    airRouteCollections,
    onCreateBookingFlight,
}) => (
    <div
        className={clsx(
            'border border-gray-100 rounded mb-4 bg-white lg:hidden',
            alerts.length > 0 && 'rounded-b-none',
        )}
    >
        {airRouteCollections.map((airRouteCollection, collectionIndex) => {
            const selectedAirRoute = selectedAirRoutes[collectionIndex]
            const airRoute = selectedAirRoute
                ? airRouteCollection.find(whereId(selectedAirRoute))
                : airRouteCollection[0]

            if (!airRoute) {
                return null
            }

            return (
                <FlightSearchPageResultCardCollectionMobile
                    mode={mode}
                    airRoute={airRoute}
                    key={selectedAirRoute}
                    index={collectionIndex}
                    collection={airRouteCollection}
                    baggageAllowed={baggageAllowed}
                    onChangeAirRoute={onChangeAirRoute}
                    selectedAirRoute={selectedAirRoute}
                    selectedAirRoutes={selectedAirRoutes}
                />
            )
        })}
        <FlightEconomicsMobileDeprecated
            charge={charge}
            hasAddon={hasAddon}
            baggageAllowed={baggageAllowed}
            onCreateBookingFlight={onCreateBookingFlight}
        />
        {alerts.map(({ content }) => {
            const [layout] = content.layout
            return (
                <CheckoutInfoCard
                    key={content._uid}
                    title={layout.title}
                    richText={layout.text}
                    iconName={layout.icon}
                    variant={layout.variant}
                    className="rounded-t-none"
                    colorVariant={layout.colorVariant}
                />
            )
        })}
    </div>
)

export default FlightSearchPageResultCardMobileDeprecated
