import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { AnimatePresence, motion } from 'framer-motion'
import useFlightProps from '../hooks/useFlightProps'
import Radio from './Radio'
import FlightOverviewTiming from './FlightOverviewTiming'
import SegmentLayoverDivider from './SegmentLayoverDivider'
import SegmentDetailsDesktop from './SegmentDetailsDesktop'

const FlightOverviewDesktop = ({
    id,
    stops,
    origin,
    carrier,
    segments,
    duration,
    isLoading,
    isSelected,
    originCity,
    hasOptions,
    destination,
    arrivalTime,
    departureTime,
    baggageAllowed,
    cardHasOptions,
    destinationCity,
    onChangeAirRoute,
    landsDifferentDay,
}) => {
    const { getFlightPropsFromSegment } = useFlightProps()
    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false)

    return (
        <>
            <div className="hidden lg:flex items-center">
                {!hasOptions && cardHasOptions ? <div className="w-6" /> : null}
                {hasOptions ? (
                    <AnimatePresence>
                        <motion.div
                            className="mr-3"
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: 'auto', opacity: 1 }}
                        >
                            <Radio
                                value={isSelected}
                                className="focus:ring-transparent"
                                onChange={() => onChangeAirRoute(id)}
                            />
                        </motion.div>
                    </AnimatePresence>
                ) : null}
                <div className="grid grid-cols-12 gap-x-2 xl:gap-x-4 flex-1">
                    <div className="flex flex-col justify-center col-span-2">
                        <div className="w-6 h-6">
                            {carrier?.imageSquareUrl && (
                                <img
                                    alt={carrier.name}
                                    src={carrier.imageSquareUrl}
                                />
                            )}
                        </div>
                        <p className="text-sm text-gray-600 mt-1">
                            {carrier.name}
                        </p>
                    </div>
                    <div className="col-span-9">
                        <FlightOverviewTiming
                            stops={stops}
                            origin={origin}
                            duration={duration}
                            originCity={originCity}
                            destination={destination}
                            arrivalTime={arrivalTime}
                            departureTime={departureTime}
                            destinationCity={destinationCity}
                            landsDifferentDay={landsDifferentDay}
                        />
                    </div>
                    <div className="flex items-center justify-end">
                        <button
                            type="button"
                            className={clsx(
                                'text-sm text-blue-bright',
                                isLoading && 'cursor-wait',
                            )}
                            data-testid="FlightOverviewMoreDetailsButtonDesktop"
                            onClick={() => !isLoading && setIsMoreDetailsOpen(!isMoreDetailsOpen)}
                        >
                            {isMoreDetailsOpen
                                ? <Trans id="Close" />
                                : <Trans id="Details" />}
                        </button>
                    </div>
                </div>
            </div>
            <AnimatePresence initial={false}>
                {isMoreDetailsOpen && (
                    <motion.div
                        animate="open"
                        exit="collapsed"
                        initial="collapsed"
                        className="overflow-hidden"
                        transition={{ duration: 0.3 }}
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        data-testid="FlightOverviewMoreDetailsDesktop"
                    >
                        {segments.map((segment, segmentIndex) => {
                            const nextSegment = segments[segmentIndex + 1]

                            return (
                                <div key={segment.id}>
                                    <div className="bg-gray-50 rounded border border-gray-100 mt-4">
                                        <SegmentDetailsDesktop
                                            {...getFlightPropsFromSegment(segment)}
                                            baggageAllowed={baggageAllowed}
                                        />
                                    </div>
                                    {nextSegment && (
                                        <SegmentLayoverDivider
                                            arrivalTime={segment.arrivalTime}
                                            departureTime={nextSegment.departureTime}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default FlightOverviewDesktop
