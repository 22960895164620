import React from 'react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import Tag from './Tag'
import FlightOverviewTimingMobile from './FlightOverviewTimingMobile'
import Stops from './Stops'

const FlightOverviewBodyMobileDeprecated = ({
    stops,
    carrier,
    duration,
    originCity,
    arrivalTime,
    departureTime,
    destinationCity,
}) => (
    <div className="flex flex-wrap">
        <div className="flex mb-4 w-full">
            <FlightOverviewTimingMobile
                stops={stops}
                arrivalTime={arrivalTime}
                departureTime={departureTime}
            />
            <div className="flex flex-col items-start gap-3 w-full">
                <div className="flex w-full align-top gap-2">
                    <p className="text-sm text-gray-800 flex flex-wrap gap-x-1">
                        <span>{originCity.name}</span>
                        <span>{originCity.nameExtension && `(${originCity.nameExtension})`}</span>
                    </p>
                </div>
                <div className="flex items-center w-full gap-x-4 gap-y-2 flex-wrap">
                    <div className="flex leading-none">
                        <div className="relative w-4 h-4 mr-2 bg-gray-100">
                            {carrier?.imageSquareUrl && (
                                <img
                                    alt={carrier.name}
                                    src={carrier.imageSquareUrl}
                                    className="absolute inset-0 object-cover h-full w-full"
                                />
                            )}
                        </div>
                        <span className="text-xs text-gray-600">
                            {carrier.name}
                        </span>
                    </div>
                    <div className="flex items-center gap-4">
                        {duration !== 0 && (
                            <p className="text-xs text-gray-600 font-medium">
                                {millisecondsToFlightDurationString(duration)}
                            </p>
                        )}
                        <Tag
                            variant="blue-outlined"
                            className="whitespace-nowrap"
                        >
                            <Stops count={stops} />
                        </Tag>
                    </div>
                </div>
                <p className="text-sm text-gray-800 flex flex-wrap gap-x-1">
                    <span>{destinationCity.name}</span>
                    <span>{destinationCity.nameExtension && `(${destinationCity.nameExtension})`}</span>
                </p>
            </div>
        </div>
    </div>
)

export default FlightOverviewBodyMobileDeprecated
