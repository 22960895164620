import React from 'react'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol, getPersonPriceFromCharge } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Button, { VARIANT_BLUE_FILLED, SIZE_LARGE } from '../../utils/Button'
import { getBaggageText } from './FlightEconomicsDesktop'
import FlightEconomicsPriceDetails from './FlightEconomicsPriceDetails'

const FlightEconomicsMobileDeprecated = ({
    charge,
    hasAddon,
    isLoading,
    isDisabled,
    baggageAllowed,
    onCreateBookingFlight,
}) => (
    <div className="lg:hidden">
        <div className="flex justify-between items-center p-4 gap-2 border-b border-t border-gray-100">
            <div>
                <p className="text-primary text-xl">
                    <span className="text-base font-semibold">&euro;</span>
                    <span data-testid="EconomicsMobile" className="font-semibold">
                        {formatPriceWithoutSymbol(Math.floor(getPersonPriceFromCharge(charge)))}
                    </span>
                    <span className="text-xs text-gray-600">
                        <span className="mx-1">/</span>
                        <Trans id="person" />
                    </span>
                </p>
                <FlightEconomicsPriceDetails charge={charge} />
            </div>
            <Button
                size={SIZE_LARGE}
                isLoading={isLoading}
                disabled={isDisabled}
                variant={VARIANT_BLUE_FILLED}
                onClick={onCreateBookingFlight}
                className="uppercase font-semibold"
                data-testid="FlightEconomicsMobileBookButton"
            >
                <Trans id="Book now" />
            </Button>
        </div>
        <div className="flex p-4 text-gray-600 text-xs">
            <Icon
                name="ticket"
                size="smaller"
                className="mr-1"
            />
            <p>Economy</p>
            <p className="text-gray-200 mx-2">
                -
            </p>
            <Icon
                size="smaller"
                className="mr-1"
                name={hasAddon || baggageAllowed ? 'bag' : 'bag-slash'}
            />
            <p>
                {getBaggageText(hasAddon, baggageAllowed)}
            </p>
        </div>
    </div>
)

export default FlightEconomicsMobileDeprecated
