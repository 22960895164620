import React from 'react'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol, getPersonPriceFromCharge } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Button, { SIZE_LARGE, VARIANT_BLUE_FILLED } from '../../utils/Button'
import FlightEconomicsPriceDetails from './FlightEconomicsPriceDetails'

export function getBaggageText(hasAddon, baggageAllowed) {
    if (baggageAllowed) {
        return <Trans id="Luggage included" />
    }
    if (hasAddon) {
        return <Trans id="Optional luggage available" />
    }
    return <Trans id="Without luggage" />
}

const FlightEconomicsDesktop = ({
    charge,
    hasAddon,
    isLoading,
    isDisabled,
    baggageAllowed,
    isAlmostSoldOut,
    onCreateBookingFlight,
}) => (
    <div className="relative border-l border-gray-100">
        {isAlmostSoldOut ? (
            <div className="text-sm text-primary absolute font-medium w-full text-right p-2">
                <Trans id="Last places!" />
            </div>
        ) : null}
        <div className="min-w-[216px] xl:min-w-[248px] px-3 xl:px-6 py-12 flex flex-col items-center justify-center h-full">
            <div className="flex gap-x-1 items-center justify-center">
                <p className="text-2xl font-bold">
                    <span className="mr-1">&euro;</span>
                    <span data-testid="FlightChargeDesktop">
                        {formatPriceWithoutSymbol(Math.floor(getPersonPriceFromCharge(charge)))}
                    </span>
                </p>
                <FlightEconomicsPriceDetails charge={charge}>
                    <Icon
                        color="gray"
                        size="smaller"
                        name="info-circle"
                        className="hover:cursor-pointer"
                    />
                </FlightEconomicsPriceDetails>
            </div>
            <Button
                size={SIZE_LARGE}
                isLoading={isLoading}
                disabled={isDisabled}
                variant={VARIANT_BLUE_FILLED}
                onClick={onCreateBookingFlight}
                className="font-semibold !px-14 mb-8 mt-4"
                data-testid="FlightEconomicsDesktopBookButton"
            >
                <Trans id="Book now" />
            </Button>
            <div className="flex items-center justify-center">
                <Icon
                    color="gray"
                    size="smaller"
                    name={hasAddon || baggageAllowed ? 'bag' : 'bag-slash'}
                />
                <p className="text-gray-600 ml-2">
                    {getBaggageText(hasAddon, baggageAllowed)}
                </p>
            </div>
        </div>
    </div>
)

export default FlightEconomicsDesktop
