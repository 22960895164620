import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import useFlightProps from '../../hooks/useFlightProps'
import Icon from '../../utils/Icon'
import Radio from '../../utils/Radio'
import ModalSlideUp from '../../utils/ModalSlideUp'
import ModalSlideUpFooter from '../../utils/ModalSlideUpFooter'
import ModalSlideUpHeader from '../../utils/ModalSlideUpHeader'
import ModalSlideUpContent from '../../utils/ModalSlideUpContent'
import Button, { VARIANT_BLUE_OUTLINED, VARIANT_BLUE_FILLED } from '../../utils/Button'
import FlightOverviewBodyMobileDeprecated from '../../utils/FlightOverviewBodyMobileDeprecated'

const ChooseAirRouteModal = ({
    onClose,
    airRoutes,
    airRouteType,
    selectedAirRoute,
    onChangeAirRoute,
    ...props
}) => {
    const [modalAirRoute, setModalAirRoute] = useState(selectedAirRoute)
    const { getFlightPropsFromAirRoute } = useFlightProps()
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={(
                    <div className="flex">
                        <Icon
                            color="blue"
                            size="smaller"
                            name={airRouteType === 0 ? 'plane-departure' : 'plane-arrival'}
                            className="mr-2"
                        />
                        {airRouteType === 0
                            ? <Trans id="Departure options" />
                            : <Trans id="Return options" />}

                    </div>
                )}
            />
            <ModalSlideUpContent>
                {airRoutes.map((airRoute) => {
                    const isSelected = airRoute.id === modalAirRoute
                    return (
                        <button
                            type="button"
                            key={airRoute.id}
                            onClick={() => setModalAirRoute(airRoute.id)}
                            className={clsx(
                                'w-full flex justify-between border rounded mb-4 p-4',
                                isSelected ? 'border-green bg-green bg-opacity-10' : 'border-gray-100 bg-gray-50'
                            )}
                        >
                            <FlightOverviewBodyMobileDeprecated
                                {...getFlightPropsFromAirRoute(airRoute)}
                            />
                            <Radio
                                readOnly
                                value={isSelected}
                            />
                        </button>
                    )
                })}
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    onClick={onClose}
                    className="flex-1 mr-4"
                    variant={VARIANT_BLUE_OUTLINED}
                >
                    <Trans id="Cancel" />
                </Button>
                <Button
                    className="flex-1"
                    variant={VARIANT_BLUE_FILLED}
                    onClick={() => {
                        onChangeAirRoute(modalAirRoute)
                        onClose()
                    }}
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default ChooseAirRouteModal
