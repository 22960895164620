import React from 'react'
import useFlightSearchExperiment from '../../hooks/useFlightSearchExperiment'
import useCollapsedAirRouteCollections from '../../hooks/useCollapsedAirRouteCollections'
import FlightSearchPageResultCardMobile from './FlightSearchPageResultCardMobile'
import FlightSearchPageResultCardMobileDeprecated from './FlightSearchPageResultCardMobileDeprecated'

const FlightSearchPageResultCardMobileWithState = ({
    airRouteCollections,
    ...props
}) => {
    const { updatedUi } = useFlightSearchExperiment()
    const {
        onExpandCollections,
        collapsedAirRoutesState,
        collapsedAirRouteCollections,
    } = useCollapsedAirRouteCollections(airRouteCollections)

    if (updatedUi) {
        return (
            <FlightSearchPageResultCardMobile
                onExpand={onExpandCollections}
                collapsedState={collapsedAirRoutesState}
                airRouteCollections={collapsedAirRouteCollections}
                {...props}
            />
        )
    }

    return (
        <FlightSearchPageResultCardMobileDeprecated
            airRouteCollections={collapsedAirRouteCollections}
            {...props}
        />
    )
}

export default FlightSearchPageResultCardMobileWithState
