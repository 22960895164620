import React from 'react'
import useFlightSearchExperiment from '../../hooks/useFlightSearchExperiment'
import useCollapsedAirRouteCollections from '../../hooks/useCollapsedAirRouteCollections'
import FlightSearchPageResultCardDesktop from './FlightSearchPageResultCardDesktop'
import FlightSearchPageResultCardDesktopDeprecated from './FlightSearchPageResultCardDesktopDeprecated'

const FlightSearchPageResultCardDesktopWithState = ({
    airRouteCollections,
    ...props
}) => {
    const { updatedUi } = useFlightSearchExperiment()
    const {
        onExpandCollections,
        collapsedAirRoutesState,
        collapsedAirRouteCollections,
    } = useCollapsedAirRouteCollections(airRouteCollections)

    if (updatedUi) {
        return (
            <FlightSearchPageResultCardDesktop
                onExpand={onExpandCollections}
                collapsedState={collapsedAirRoutesState}
                airRouteCollections={collapsedAirRouteCollections}
                {...props}
            />
        )
    }

    return (
        <FlightSearchPageResultCardDesktopDeprecated
            onExpand={onExpandCollections}
            collapsedState={collapsedAirRoutesState}
            airRouteCollections={collapsedAirRouteCollections}
            {...props}
        />
    )
}

export default FlightSearchPageResultCardDesktopWithState
