import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { FLIGHT_MODE_MULTI_CITY } from '@connections/constants'
import useFormatDate from '../hooks/useFormatDate'
import Icon from './Icon'
import SelectButton from './SelectButton'
import FlightDetailsModal from './FlightDetailsModal'
import FlightOverviewHeading from './FlightOverviewHeading'
import FlightOverviewBodyMobileDeprecated from './FlightOverviewBodyMobileDeprecated'

const FlightOverviewMobileDeprecated = ({
    mode,
    stops,
    origin,
    carrier,
    segments,
    duration,
    hasOptions,
    originCity,
    destination,
    arrivalTime,
    airRouteType,
    departureTime,
    baggageAllowed,
    destinationCity,
    onChangeAirRoute,
    selectedAirRoute,
    landsDifferentDay,
}) => {
    const format = useFormatDate()
    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false)

    return (
        <div className="lg:hidden py-6 px-4">
            <FlightOverviewHeading
                departureTime={departureTime}
                airRouteType={mode === FLIGHT_MODE_MULTI_CITY ? '' : airRouteType}
            />
            {hasOptions && selectedAirRoute === null
                ? (
                    <>
                        <p className="text-xs text-gray-600">
                            {airRouteType === 0
                                ? <Trans id="There are multiple departure options for this price range. Please select one below" />
                                : <Trans id="There are multiple return options for this price range. Please select one below" />}

                        </p>
                        <SelectButton
                            isPlaceholder
                            onClick={onChangeAirRoute}
                            className="text-sm text-gray-400 mt-4"
                        >
                            {airRouteType === 0
                                ? <Trans id="Select departure" />
                                : <Trans id="Select return" />}
                        </SelectButton>
                    </>
                )
                : (
                    <>
                        <FlightOverviewBodyMobileDeprecated
                            stops={stops}
                            origin={origin}
                            carrier={carrier}
                            duration={duration}
                            originCity={originCity}
                            arrivalTime={arrivalTime}
                            destination={destination}
                            departureTime={departureTime}
                            destinationCity={destinationCity}
                        />
                        {landsDifferentDay && (
                            <p className="ml-6 mt-1 text-xs text-primary">
                                <Trans id="Arrival" />
                                {' '}
                                {format(new Date(arrivalTime), 'dd.MM.yy')}
                            </p>
                        )}
                        <div className="flex justify-between mt-3 mx-5">
                            <button
                                type="button"
                                className="flex items-center"
                                onClick={() => setIsMoreDetailsOpen(true)}
                                data-testid="FlightOverviewMoreDetailsButtonMobile"
                            >
                                <Icon
                                    size="smaller"
                                    name="info-circle"
                                    className="ml-0.5"
                                    color="blue-bright"
                                />
                                <p className="text-xs underline text-blue-bright ml-1">
                                    <Trans id="More details" />
                                </p>
                            </button>
                            {hasOptions && (
                                <button
                                    type="button"
                                    onClick={onChangeAirRoute}
                                    className="flex items-center"
                                >
                                    <Icon
                                        name="pen"
                                        size="smaller"
                                        className="ml-0.5"
                                        color="blue-bright"
                                    />
                                    <p className="text-xs underline text-blue-bright ml-1">
                                        <Trans id="Change flight" />
                                    </p>
                                </button>
                            )}
                        </div>
                        {isMoreDetailsOpen && (
                            <FlightDetailsModal
                                segments={segments}
                                departureTime={departureTime}
                                baggageAllowed={baggageAllowed}
                                testId="FlightOverviewMoreDetailsMobile"
                                onClose={() => setIsMoreDetailsOpen(false)}
                            />
                        )}
                    </>
                )}
        </div>
    )
}

export default FlightOverviewMobileDeprecated
