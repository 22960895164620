import { differenceInDays, format } from 'date-fns'
import { whereName, whereValue } from '@connections/utils'
import { getTemplatePrice } from '../../util/booking/templates'
import useAppState from './useAppState'

const useBreakAnalyticsValues = () => {
    const { destinations, airportCodes } = useAppState()

    const getLocationAnalyticsValues = (flight) => {
        const [{ segments }] = flight.airRoutes
        const lastSegment = segments[segments.length - 1]
        const { destination: { code: destinationCode } } = lastSegment
        let city = destinations.find(whereValue(destinationCode))
        if (!city) {
            const airport = airportCodes[destinationCode]
            city = destinations.find(whereName(airport?.name))
        }
        const country = destinations.find(whereValue(city?.countryCode))
        const region = destinations.find(whereValue(country?.continentCode))
        return {
            city: city?.name,
            area: region?.name,
            country: country?.name,
        }
    }

    const getTimingAnalyticsValues = (endDate, startDate) => {
        if (endDate && startDate) {
            const end = new Date(endDate)
            const start = new Date(startDate)

            return {
                returnDay: format(end, 'dd-MM-yyyy'),
                departureDay: format(start, 'dd-MM-yyyy'),
                duration: differenceInDays(end, start) + 2,
                bookingWindow: differenceInDays(start, new Date()),
            }
        }

        return {}
    }

    const getTemplateAnalyticsValues = (startDate, endDate, templatePricing, hotels, tour, activities, adults, name, collection) => {
        const price = getTemplatePrice(templatePricing, hotels, tour, activities)
        const selectedHotel = templatePricing.hotels.find(({ productId }) => hotels.some((id) => id === productId))
        return {
            price,
            hotel: selectedHotel?.name,
            route: !!collection || name,
            numberOfAdults: adults.length,
            ...getTimingAnalyticsValues(endDate, startDate),
            ...getLocationAnalyticsValues(templatePricing.flights[0]),
        }
    }

    return {
        getTimingAnalyticsValues,
        getLocationAnalyticsValues,
        getTemplateAnalyticsValues,
    }
}

export default useBreakAnalyticsValues
