import React from 'react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import useFormatDate from '../hooks/useFormatDate'
import Stops from './Stops'

const STOP_SIZE = 5

const FlightOverviewTiming = ({
    stops,
    origin,
    duration,
    originCity,
    arrivalTime,
    destination,
    departureTime,
    destinationCity,
    landsDifferentDay,
}) => {
    const format = useFormatDate()
    return (
        <div className="grid grid-cols-3">
            <div className="text-right text-gray-600">
                <p
                    className="font-medium text-2xl text-black"
                    data-testid="FlightOverviewTimingDeparture"
                >
                    {format(new Date(departureTime), 'HH:mm')}
                </p>
                <p className="text-lg">
                    {originCity.name}
                    {originCity.nameExtension}
                    {' '}
                    [{origin}]
                </p>
            </div>
            <div className="flex flex-col items-center mx-4">
                <p className="text-sm text-gray-600">
                    <Stops count={stops} />
                </p>
                <div className="my-3">
                    <div
                        className="relative w-20"
                        style={{ height: STOP_SIZE }}
                    >
                        <div
                            style={{ top: (STOP_SIZE / 2) }}
                            className="absolute w-full h-px bg-gray-200"
                        />
                        {stops > 0 && (
                            <div className="absolute w-full flex justify-evenly top-px">
                                {Array.from(Array(stops).keys()).map((_, index) => (
                                    <div
                                        key={`stops-${index}`}
                                        style={{
                                            width: STOP_SIZE,
                                            height: STOP_SIZE,
                                        }}
                                        className="bg-blue-bright rounded"
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <p className="text-sm text-gray-600 text-center">
                    {duration !== 0 && millisecondsToFlightDurationString(duration)}
                </p>
            </div>
            <div className="text-gray-600">
                <p
                    className="font-medium text-2xl text-black"
                    data-testid="FlightOverviewTimingDeparture"
                >
                    {format(new Date(arrivalTime), 'HH:mm')}
                    {landsDifferentDay ? (
                        <>
                            {' '}
                            <span className="text-lg text-primary whitespace-nowrap">
                                {format(new Date(arrivalTime), 'd MMM').slice(0, -1)}
                            </span>
                        </>
                    ) : null}
                </p>
                <p className="text-lg">
                    {destinationCity.name}
                    {' '}
                    {originCity.destinationCity ? `${originCity.destinationCity} ` : null}
                    [{destination}]
                </p>
            </div>
        </div>
    )
}

export default FlightOverviewTiming
