import React from 'react'
import useFlightSearchExperiment from '../hooks/useFlightSearchExperiment'
import FlightOverviewMobile from './FlightOverviewMobile'
import FlightOverviewDesktop from './FlightOverviewDesktop'
import FlightOverviewMobileDeprecated from './FlightOverviewMobileDeprecated'
import FlightOverviewDesktopDeprecated from './FlightOverviewDesktopDeprecated'

const FlightOverview = ({
    ...props
}) => {
    const { updatedUi } = useFlightSearchExperiment()

    if (updatedUi) {
        return (
            <>
                <FlightOverviewMobile {...props} />
                <FlightOverviewDesktop {...props} />
            </>
        )
    }

    return (
        <>
            <FlightOverviewMobileDeprecated {...props} />
            <FlightOverviewDesktopDeprecated {...props} />
        </>
    )
}

export default FlightOverview
