import React from 'react'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol, getPersonPriceFromCharge } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Button, { VARIANT_BLUE_FILLED, SIZE_LARGE } from '../../utils/Button'
import { getBaggageText } from './FlightEconomicsDesktop'
import FlightEconomicsPriceDetails from './FlightEconomicsPriceDetails'

const FlightEconomicsMobile = ({
    charge,
    hasAddon,
    isLoading,
    isDisabled,
    baggageAllowed,
    onCreateBookingFlight,
}) => (
    <div className="lg:hidden px-4 py-6 border-t border-gray-100">
        <div className="flex justify-between">
            <div className="flex gap-x-1 items-center justify-center">
                <p className="text-2xl font-bold">
                    <span className="mr-1">&euro;</span>
                    <span data-testid="EconomicsMobile">
                        {formatPriceWithoutSymbol(Math.floor(getPersonPriceFromCharge(charge)))}
                    </span>
                </p>
                <FlightEconomicsPriceDetails charge={charge}>
                    <Icon
                        color="gray"
                        size="smaller"
                        name="info-circle"
                        className="hover:cursor-pointer"
                    />
                </FlightEconomicsPriceDetails>
            </div>
            <div className="flex items-center justify-center">
                <Icon
                    color="gray"
                    size="smaller"
                    name={hasAddon || baggageAllowed ? 'bag' : 'bag-slash'}
                />
                <p className="text-gray-600 ml-2">
                    {getBaggageText(hasAddon, baggageAllowed)}
                </p>
            </div>
        </div>
        <Button
            size={SIZE_LARGE}
            isLoading={isLoading}
            disabled={isDisabled}
            variant={VARIANT_BLUE_FILLED}
            onClick={onCreateBookingFlight}
            className="font-semibold w-full mt-6"
            data-testid="FlightEconomicsMobileBookButton"
        >
            <Trans id="Book now" />
        </Button>
    </div>
)

export default FlightEconomicsMobile
