import * as constants from '@connections/constants'
import { differenceInDays, format, parseISO } from 'date-fns'

const {
    CURRENCY_ROUNDING,
    BOOKING_TYPE_GIFTCARD: GIFTCARD,
    BOOKING_ITEM_TYPE_FLIGHT: FLIGHT_ITEM,
} = constants

const getRouteFromAirRoutes = (airRoutes, full) => (
    airRoutes.reduce((flightNumbers, airRoute) => {
        const { segments } = airRoute
        const airRouteFlightNumbers = segments.map((segment) => {
            const addition = full ? `-${segment.carrier}` : ''
            return (
                `${segment.origin.code + addition}-${segment.destination.code}${addition}`
            )
        }).join('-')
        return [...flightNumbers, airRouteFlightNumbers]
    }, []).join('-')
)

const getDates = (prepend, departureDate, returnDate) => ({
    [`${prepend}-departure-day`]: departureDate ? format(departureDate, 'dd-MM-yyyy') : null,
    [`${prepend}-return-day`]: returnDate ? format(returnDate, 'dd-MM-yyyy') : null,
    [`${prepend}-duration`]: returnDate ? differenceInDays(returnDate, departureDate) : null,
    [`${prepend}-booking-window`]: departureDate ? differenceInDays(departureDate, new Date()) + 1 : null,
})

export const getTransactionId = (booking) => {
    switch (booking.type) {
        case GIFTCARD:
            return booking.data.paxFile?.ID
        default:
            return booking.data.reservationCompleted?.BookingNumber
    }
}

export const getBookingPaymentMeta = (booking) => {
    const returnDate = booking.data.endDate && parseISO(booking.data.endDate)
    const departureDate = booking.data.startDate && parseISO(booking.data.startDate)
    return {
        'booking-type': booking.type,
        transactionTotal: booking.totalPrice,
        transactionId: getTransactionId(booking),
        'booking-passengers-adt': booking.adults,
        'booking-passengers-inf': booking.infants,
        'booking-total-price': booking.totalPrice,
        'booking-passengers-chd': booking.children,
        'booking-passengers': booking.travelers?.length,
        'booking-date': format(new Date(), 'dd-MM-yyyy'),
        ...getDates('booking', departureDate, returnDate),
        transactionProducts: booking.items.map((item) => {
            const productItem = {
                quantity: 1,
                category: item.type,
                name: item.data.name || item.type,
                price: item.data.price / CURRENCY_ROUNDING,
            }
            switch (item.type) {
                case FLIGHT_ITEM:
                    return {
                        ...productItem,
                        price: item.data.flight.charge.total,
                        sku: getRouteFromAirRoutes(item.data.flight.airRoutes),
                    }
                default:
                    return {
                        ...productItem,
                        sku: item.productId || item.type,
                        price: item.data.price / CURRENCY_ROUNDING,
                    }
            }
        })
    }
}

export const getBasicBookingMeta = (booking) => ({
    'booking-uid': booking.uid,
    'booking-type': booking.type,
})

export const getBreakMetaData = (values, prefix, breakType = 'PACKAGE') => {
    const metaData = {
        engine: 'new',
        'booking-type': breakType,
    }

    metaData[`${prefix}-price`] = values.price
    metaData[`${prefix}-hotel`] = values.hotel
    metaData[`${prefix}-route`] = values.route
    metaData[`${prefix}-duration`] = values.duration
    metaData[`${prefix}-return-day`] = values.returnDay
    metaData[`${prefix}-destination-city`] = values.city
    metaData[`${prefix}-destination-area`] = values.area
    metaData[`${prefix}-departure-day`] = values.departureDay
    metaData[`${prefix}-destination-country`] = values.country
    metaData[`${prefix}-number-adults`] = values.numberOfAdults
    metaData[`${prefix}-booking-window`] = values.bookingWindow

    if (values.products) {
        metaData[`${prefix}-products`] = values.products
    }
    if (values.totalPrice) {
        metaData[`${prefix}-total-price`] = values.totalPrice
    }
    if (values.transactionId) {
        metaData.transactionId = values.transactionId
    }

    return metaData
}
