import React from 'react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import useFormatDate from '../hooks/useFormatDate'
import Stops from './Stops'

const STOP_SIZE = 5

const FlightOverviewTimingDeprecated = ({
    stops,
    duration,
    className,
    arrivalTime,
    departureTime,
    landsDifferentDay,
}) => {
    const format = useFormatDate()
    return (
        <div className={className}>
            <p className="text-sm text-gray-600 text-center uppercase">
                <Stops count={stops} />
            </p>
            <div className="flex justify-center items-center gap-4">
                <p
                    className="font-bold"
                    data-testid="FlightOverviewTimingDeparture"
                >
                    {format(new Date(departureTime), 'HH:mm')}
                </p>
                <div className="text-sm text-gray-600 text-center w-8 xl:w-24">
                    <div
                        style={{ height: STOP_SIZE }}
                        className="relative my-2 w-full"
                    >
                        <div
                            style={{
                                top: (STOP_SIZE / 2),
                            }}
                            className="absolute w-full h-px bg-gray-200"
                        />
                        {stops > 0 && (
                            <div className="absolute w-full flex justify-evenly top-px">
                                {Array.from(Array(stops).keys()).map((_, index) => (
                                    <div
                                        key={`stops-${index}`}
                                        style={{
                                            width: STOP_SIZE,
                                            height: STOP_SIZE,
                                        }}
                                        className="bg-blue-bright rounded"
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <p
                    className="font-bold"
                    data-testid="FlightOverviewTimingArrival"
                >
                    {format(new Date(arrivalTime), 'HH:mm')}
                </p>
            </div>
            <div className="grid grid-cols-3">
                <div />
                <p className="text-sm text-gray-600 text-center">
                    {duration !== 0 && millisecondsToFlightDurationString(duration)}
                </p>
                <p className="text-sm text-primary font-medium text-right uppercase">
                    {landsDifferentDay && format(new Date(arrivalTime), 'd MMM').slice(0, -1)}
                </p>
            </div>
        </div>
    )
}

export default FlightOverviewTimingDeprecated
