import { useState } from 'react'

const CUTOFF_POINT = 2

export default function useCollapsedAirRouteCollections(airRouteCollections) {
    const [collapsedState, setCollapsedState] = useState(airRouteCollections.map((airRouteCollection) => ({
        isCollapsed: airRouteCollection.length > CUTOFF_POINT,
        collapsedCount: airRouteCollection.length - CUTOFF_POINT + 1,
    })))
    const collapsedAirRouteCollections = airRouteCollections.map((airRouteCollection, index) => {
        if (collapsedState[index].isCollapsed && airRouteCollection.length > CUTOFF_POINT) {
            return airRouteCollection.slice(0, 1)
        }
        return airRouteCollection
    })

    const handleExpandCollections = (index) => {
        const newState = [...collapsedState]
        newState[index] = { collapsed: false }
        setCollapsedState(newState)
    }

    return {
        collapsedAirRouteCollections,
        collapsedAirRoutesState: collapsedState,
        onExpandCollections: handleExpandCollections,
    }
}
