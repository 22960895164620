import React from 'react'
import FlightEconomicsMobileDeprecated from './FlightEconomicsMobileDeprecated'
import FlightEconomicsDesktopDeprecated from './FlightEconomicsDesktopDeprecated'

const FlightEconomics = ({
    ...props
}) => (
    <>
        <FlightEconomicsMobileDeprecated {...props} />
        <FlightEconomicsDesktopDeprecated {...props} />
    </>
)

export default FlightEconomics
