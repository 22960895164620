import { Trans } from '@lingui/react'
import React from 'react'

const Stops = ({
    count,
}) => {
    if (count === 0) {
        return <Trans id="Direct" />
    }
    return (
        <Trans
            id="{count} {count, plural, =1 {stop} other {stops}}"
            values={{ count }}
        />
    )
}

export default Stops
