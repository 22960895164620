import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Tag, { VARIANT_BLUE_OUTLINED, VARIANT_GREEN_OUTLINED, VARIANT_ORANGE_OUTLINED } from '../../utils/Tag'

const FlightSearchPageResultCardLabels = ({
    hasLabel,
    className,
    isFastest,
    isCheapest,
    isRecommended,
}) => {
    if (!hasLabel) {
        return false
    }

    return (
        <div className={clsx('flex gap-x-1.5', className)}>
            {isCheapest ? (
                <Tag variant={VARIANT_BLUE_OUTLINED} className="!lowercase w-fit">
                    <span className="capitalize">
                        <Trans id="Cheapest" />
                    </span>
                </Tag>
            ) : null}
            {isRecommended ? (
                <Tag variant={VARIANT_GREEN_OUTLINED} className="!lowercase w-fit">
                    <span className="capitalize">
                        <Trans id="Recommended" />
                    </span>
                </Tag>
            ) : null}
            {isFastest ? (
                <Tag variant={VARIANT_ORANGE_OUTLINED} className="!lowercase w-fit">
                    <span className="capitalize">
                        <Trans id="Fastest" />
                    </span>
                </Tag>
            ) : null}
        </div>

    )
}

export default FlightSearchPageResultCardLabels
