import React, { useState } from 'react'
import isEqual from 'lodash.isequal'
import { hasPossibleBrandedFares, fareTimeStringToMilliseconds } from '@connections/utils'
import { getCabinClass } from '../../../util/booking/flights'
import { useQuickSearchFlightQueryParams } from '../../content/useQuickSearchFlightFormState'
import { isAlertForFlight, isAlertForGeneralBookingDetails } from '../../../util/alerts/checks'
import useAppState from '../../hooks/useAppState'
import useFlightSearchExperiment from '../../hooks/useFlightSearchExperiment'
import FlightSearchPageResultCardMobileWithState from './FlightSearchPageResultCardMobileWithState'
import FlightSearchPageResultCardDesktopWithState from './FlightSearchPageResultCardDesktopWithState'

// AirRoutes can have "twins" but the segment ids will always be unique.
// That's why we filter the id out to make the comparison.
const omitId = (({ id, ...rest }) => ({ ...rest }))

const getPossibleAirRoutes = (airRoutes, selectedId) => {
    const selectedAirRoute = airRoutes.find(({ id }) => id === selectedId)
    if (typeof selectedAirRoute === 'undefined') {
        return []
    }
    const selectedSegments = selectedAirRoute.segments.map(omitId)
    return airRoutes.reduce((airRoutesAcc, { fareId, segments }) => {
        const airRouteSegments = segments.map(omitId)
        const hasTheSameSegment = isEqual(airRouteSegments, selectedSegments)
        if (hasTheSameSegment) {
            return [...airRoutesAcc, fareId]
        }
        return airRoutesAcc
    }, [])
}

const getUniqueFareId = (airRoutes, selectedAirRoutes) => {
    if (selectedAirRoutes.length === 1) {
        // For some reason it's possible no airroute is found here. It's still unclear why this happens (edge case)
        return airRoutes.find(({ id }) => selectedAirRoutes[0] === id).fareId
    }
    const [fareId] = selectedAirRoutes.reduce((acc, selectedAirRoute) => {
        const possibleAirRoutes = getPossibleAirRoutes(airRoutes, selectedAirRoute)
        if (acc.length === 0) {
            return possibleAirRoutes
        }
        return acc.filter((id) => possibleAirRoutes.includes(id))
    }, [])
    return fareId
}

const sortAirRoutes = (a, b) => {
    const [firstSegmentA] = a.segments
    const [firstSegmentB] = b.segments
    const departureMsA = new Date(firstSegmentA.departureTime).getTime()
    const departureMsB = new Date(firstSegmentB.departureTime).getTime()
    const departureDiff = departureMsA - departureMsB
    if (departureDiff === 0) {
        const flightTimeMsA = fareTimeStringToMilliseconds(a.travelTime)
        const flightTimeMsB = fareTimeStringToMilliseconds(b.travelTime)
        return flightTimeMsA - flightTimeMsB
    }
    return departureDiff
}

export const combineAirRoutes = (airRoutes) => (
    airRoutes.sort(sortAirRoutes).reduce((acc, airRoute) => {
        const airRouteSegments = airRoute.segments.map(omitId)
        const existingAirRoute = acc.flat().find(({ segments }) => {
            if (!segments) {
                return null
            }
            const existingSegments = segments.map(omitId)
            return isEqual(existingSegments, airRouteSegments)
        })
        if (existingAirRoute) {
            return acc
        }
        const newAirRoutes = [...acc]
        const lastIndex = acc.length - 1
        const previousAirRoutes = newAirRoutes[lastIndex]
        const previousAirRoute = previousAirRoutes[previousAirRoutes.length - 1]
        let isNextFlight = false
        if (previousAirRoute) {
            const previousDepartureTime = new Date(previousAirRoute.segments[0].departureTime)
            const currentDepartureTime = new Date(airRoute.segments[0].departureTime)
            const previousDepartureDate = previousDepartureTime.getUTCDate()
            const currentDepartureDate = currentDepartureTime.getUTCDate()
            isNextFlight = previousDepartureDate !== currentDepartureDate
        }
        const index = isNextFlight ? lastIndex + 1 : lastIndex
        const current = acc[index] || []
        newAirRoutes[index] = [...current, airRoute]
        return newAirRoutes
    }, [[]])
)

const FlightSearchPageResultCard = ({
    alerts,
    fare = {},
    isFastest,
    isCheapest,
    resultsRef,
    selectedFare,
    isRecommended,
    onRemoveSelection,
    onCreateBookingFlight,
}) => {
    const {
        ticket,
        charge,
        provider,
        airRoutes,
        platingCarrier,
        baggageAllowed,
    } = fare
    const { airportCodes } = useAppState()
    const { luggageInQsm } = useFlightSearchExperiment()
    const combinedAirRoutes = combineAirRoutes(airRoutes)
    const [{ mode, infants, fareType }] = useQuickSearchFlightQueryParams()
    const [selectedAirRoutes, setSelectedAirRoutes] = useState(combinedAirRoutes.map(([{ id }]) => id))
    const cabinClass = getCabinClass(fareType)
    const hasAddon = luggageInQsm && (fare.isLowCost || (!baggageAllowed && hasPossibleBrandedFares(fare, mode, infants, cabinClass)))

    const handleCreateBookingFlight = () => {
        const fareId = getUniqueFareId(airRoutes, selectedAirRoutes)
        onCreateBookingFlight(fareId)
    }
    const handleChangeSelectedAirRoute = (value, index) => {
        const newAirRoutes = [...selectedAirRoutes]
        newAirRoutes[index] = value
        setSelectedAirRoutes(newAirRoutes)
    }

    const props = {
        mode,
        charge,
        provider,
        hasAddon,
        isFastest,
        resultsRef,
        isCheapest,
        isRecommended,
        platingCarrier,
        baggageAllowed,
        onRemoveSelection,
        selectedAirRoutes,
        isSelected: selectedFare === ticket,
        airRouteCollections: combinedAirRoutes,
        onChangeAirRoute: handleChangeSelectedAirRoute,
        onCreateBookingFlight: handleCreateBookingFlight,
        hasLabel: isFastest || isCheapest || isRecommended,
        alerts: alerts.filter(({ content }) => {
            const [{ departureTime }] = airRoutes[0].segments
            const isAlertForAirRouteCollections = combinedAirRoutes[0].every((airRoute) => {
                const combinedFare = { ...fare, airRoutes: [airRoute] }
                return isAlertForFlight(content, combinedFare, airportCodes)
            })
            const isAlertForGeneralDetails = isAlertForGeneralBookingDetails(content, charge.total, departureTime)
            return isAlertForAirRouteCollections && isAlertForGeneralDetails
        })
    }
    return (
        <div role="listitem">
            <FlightSearchPageResultCardMobileWithState {...props} />
            <FlightSearchPageResultCardDesktopWithState {...props} />
        </div>
    )
}

export default FlightSearchPageResultCard
