import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { motion } from 'framer-motion'
import useFlightProps from '../../hooks/useFlightProps'
import useLocalizedAirline from '../../hooks/useLocalizedAirline'
import Icon from '../../utils/Icon'
import CheckoutInfoCard from '../../utils/CheckoutInfoCard'
import Button, { SIZE_SMALLEST, VARIANT_BLUE_OUTLINED } from '../../utils/Button'
import FlightOverviewDesktopDeprecated from '../../utils/FlightOverviewDesktopDeprecated'
import FlightEconomics from './FlightEconomics'

const FlightSearchPageResultCardDesktopDeprecated = ({
    mode,
    alerts,
    charge,
    hasAddon,
    onExpand,
    isSelected,
    resultsRef,
    platingCarrier,
    collapsedState,
    baggageAllowed,
    onChangeAirRoute,
    selectedAirRoutes,
    onRemoveSelection,
    airRouteCollections,
    onCreateBookingFlight,
}) => {
    const getLocalizedAirline = useLocalizedAirline()
    const { getFlightPropsFromAirRoute } = useFlightProps()
    const { name, imageUrl } = getLocalizedAirline(platingCarrier)

    return (
        <div
            className={clsx(
                'hidden lg:block mb-4',
                isSelected && 'bg-gray-100 -mx-6 px-6 pt-3 pb-6'
            )}
            ref={isSelected ? resultsRef : null}
            data-testid={isSelected ? 'FlightSearchPageResultCardDesktopSelected' : 'FlightSearchPageResultCardDesktop'}
        >
            {isSelected && (
                <button
                    type="button"
                    className="ml-auto flex items-center text-sm mb-2 gap-1 font-medium"
                    onClick={onRemoveSelection}
                    data-testid="FlightSearchPageResultCardDesktopRemoveSelection"
                >
                    <Trans id="Remove selection" />
                    <Icon name="cross" size="smaller" />
                </button>
            )}
            <div className="w-40 bg-white py-2 px-4 -mb-0.5 relative border border-gray-100 border-b-0 rounded-t">
                <img
                    alt={name}
                    src={imageUrl}
                />
            </div>
            <div
                className={clsx(
                    'flex border border-gray-100 rounded bg-white',
                    alerts.length > 0 ? 'rounded-b-none' : ''
                )}
            >
                <div className="flex-grow">
                    {airRouteCollections.map((airRouteCollection, collectionIndex) => {
                        const { isCollapsed, collapsedCount } = collapsedState[collectionIndex]
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={collectionIndex}>
                                {collectionIndex !== 0 && (
                                    <div className="h-px border-b border-gray-200 border-dashed w-11/12 mx-auto" />
                                )}
                                {airRouteCollection.map((airRoute, index, array) => (
                                    <motion.div
                                        key={airRoute.id}
                                        className="overflow-hidden"
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        transition={{ duration: isCollapsed ? 0.15 : 0 }}
                                    >
                                        <FlightOverviewDesktopDeprecated
                                            mode={mode}
                                            index={index}
                                            hasOptions={array.length > 1}
                                            amountOfFlights={array.length}
                                            baggageAllowed={baggageAllowed}
                                            isSelected={selectedAirRoutes[collectionIndex] === airRoute.id}
                                            onChangeAirRoute={(value) => onChangeAirRoute(value, collectionIndex)}
                                            {...getFlightPropsFromAirRoute(airRoute)}
                                        />
                                    </motion.div>
                                ))}
                                {isCollapsed && (
                                    <div className="w-full flex justify-center pb-6">
                                        <Button
                                            iconSize="smallest"
                                            size={SIZE_SMALLEST}
                                            iconBeforeName="clock"
                                            variant={VARIANT_BLUE_OUTLINED}
                                            onClick={() => onExpand(collectionIndex)}
                                        >
                                            <Trans id="Show other flight times" />
                                            {' '}
                                            ({collapsedCount})
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
                <FlightEconomics
                    charge={charge}
                    hasAddon={hasAddon}
                    baggageAllowed={baggageAllowed}
                    onCreateBookingFlight={onCreateBookingFlight}
                    isAlmostSoldOut={airRouteCollections.some((collection) => collection.some((airRoute) => {
                        const isSelectedAirRoute = selectedAirRoutes.includes(airRoute.id)
                        const isAlmostSoldOut = airRoute.segments.some(({ availableSeats }) => availableSeats < 4)
                        return isSelectedAirRoute && isAlmostSoldOut
                    }))}
                />
            </div>
            {alerts.map(({ content }) => {
                const [layout] = content.layout
                return (
                    <CheckoutInfoCard
                        key={content._uid}
                        title={layout.title}
                        richText={layout.text}
                        iconName={layout.icon}
                        variant={layout.variant}
                        className="rounded-t-none"
                        colorVariant={layout.colorVariant}
                    />
                )
            })}
        </div>
    )
}

export default FlightSearchPageResultCardDesktopDeprecated
