import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { motion } from 'framer-motion'
import { HATEC_PROVIDER_AIRGATEWAY } from '@connections/constants'
import useFlightProps from '../../hooks/useFlightProps'
import CheckoutInfoCard from '../../utils/CheckoutInfoCard'
import FlightOverviewDesktop from '../../utils/FlightOverviewDesktop'
import Button, { SIZE_SMALLEST, VARIANT_BLUE_OUTLINED } from '../../utils/Button'
import FlightEconomicsDesktop from './FlightEconomicsDesktop'
import FlightSearchPageResultCardLabels from './FlightSearchPageResultCardLabels'

const FlightSearchPageResultCardDesktop = ({
    alerts,
    charge,
    provider,
    hasAddon,
    onExpand,
    hasLabel,
    isFastest,
    isCheapest,
    isRecommended,
    collapsedState,
    baggageAllowed,
    onChangeAirRoute,
    selectedAirRoutes,
    airRouteCollections,
    onCreateBookingFlight,
}) => {
    const { getFlightPropsFromAirRoute } = useFlightProps()
    const cardHasOptions = airRouteCollections.some(((airRouteCollection) => airRouteCollection.length > 1))
    const isAlmostSoldOut = provider !== HATEC_PROVIDER_AIRGATEWAY && airRouteCollections.some((collection) => collection.some((airRoute) => {
        const isSelectedAirRoute = selectedAirRoutes.includes(airRoute.id)
        return isSelectedAirRoute && airRoute.segments.some(({ availableSeats }) => availableSeats < 4)
    }))

    return (
        <div className="mb-4">
            <div className={clsx('hidden lg:flex bg-white border border-gray-100 rounded-md', alerts.length > 0 && 'rounded-b-none')}>
                <div className={clsx('px-3 xl:px-6 flex-1', hasLabel ? 'pt-6 pb-9' : 'py-9')}>
                    <FlightSearchPageResultCardLabels
                        className="mb-4"
                        hasLabel={hasLabel}
                        isFastest={isFastest}
                        isCheapest={isCheapest}
                        isRecommended={isRecommended}
                    />
                    {airRouteCollections.map((airRouteCollection, collectionIndex) => {
                        const { isCollapsed, collapsedCount } = collapsedState[collectionIndex]

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={collectionIndex}>
                                {collectionIndex !== 0 && (
                                    <div className="h-px border-b border-gray-200 border-dashed w-11/12 mx-auto my-8" />
                                )}
                                <div className="flex flex-col gap-y-6">
                                    {airRouteCollection.map((airRoute, index, array) => (
                                        <motion.div
                                            key={airRoute.id}
                                            className="overflow-hidden"
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            transition={{ duration: isCollapsed ? 0.15 : 0 }}
                                        >
                                            <FlightOverviewDesktop
                                                index={index}
                                                hasOptions={array.length > 1}
                                                baggageAllowed={baggageAllowed}
                                                cardHasOptions={cardHasOptions}
                                                isSelected={selectedAirRoutes[collectionIndex] === airRoute.id}
                                                onChangeAirRoute={(value) => onChangeAirRoute(value, collectionIndex)}
                                                {...getFlightPropsFromAirRoute(airRoute)}
                                            />
                                        </motion.div>
                                    ))}
                                </div>
                                {isCollapsed ? (
                                    <div className="grid grid-cols-12 my-3">
                                        <div className="col-span-2" />
                                        <div className="relative col-span-9 flex justify-center">
                                            <Button
                                                iconSize="smallest"
                                                size={SIZE_SMALLEST}
                                                iconBeforeName="clock"
                                                variant={VARIANT_BLUE_OUTLINED}
                                                onClick={() => onExpand(collectionIndex)}
                                                className="absolute whitespace-nowrap -mt-1"
                                            >
                                                <Trans id="Show other flight times" />
                                                {' '}
                                                ({collapsedCount})
                                            </Button>
                                            <div />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )
                    })}
                </div>
                <FlightEconomicsDesktop
                    charge={charge}
                    hasAddon={hasAddon}
                    baggageAllowed={baggageAllowed}
                    isAlmostSoldOut={isAlmostSoldOut}
                    onCreateBookingFlight={onCreateBookingFlight}
                />
            </div>
            {alerts.map(({ content }) => {
                const [layout] = content.layout
                return (
                    <CheckoutInfoCard
                        key={content._uid}
                        title={layout.title}
                        richText={layout.text}
                        iconName={layout.icon}
                        variant={layout.variant}
                        className="rounded-t-none"
                        colorVariant={layout.colorVariant}
                    />
                )
            })}
        </div>
    )
}

export default FlightSearchPageResultCardDesktop
