import React, { useState } from 'react'
import useFlightProps from '../../hooks/useFlightProps'
import FlightOverviewMobileDeprecated from '../../utils/FlightOverviewMobileDeprecated'
import ChooseAirRouteModal from './ChooseAirRouteModal'

const FlightSearchPageResultCardCollectionMobile = ({
    mode,
    index,
    airRoute,
    collection,
    baggageAllowed,
    onChangeAirRoute,
    selectedAirRoute,
    selectedAirRoutes,
}) => {
    const { getFlightPropsFromAirRoute } = useFlightProps()
    const [selectedCollection, setSelectedCollection] = useState(null)

    return (
        <div>
            {index !== 0 && (
                <div className="h-px border-b border-gray-200 border-dashed w-11/12 mx-auto" />
            )}
            <FlightOverviewMobileDeprecated
                mode={mode}
                hasOptions={collection.length > 1}
                selectedAirRoute={selectedAirRoute}
                onChangeAirRoute={() => setSelectedCollection(selectedAirRoutes[index])}
                {...getFlightPropsFromAirRoute(airRoute)}
                baggageAllowed={baggageAllowed}
            />
            {selectedCollection !== null && (
                <ChooseAirRouteModal
                    key={collection}
                    airRouteType={0}
                    airRoutes={collection}
                    selectedAirRoute={selectedAirRoute}
                    onClose={() => setSelectedCollection(null)}
                    onChangeAirRoute={(value) => onChangeAirRoute(value, index)}
                />
            )}
        </div>
    )
}

export default FlightSearchPageResultCardCollectionMobile
