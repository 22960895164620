import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import { motion, AnimatePresence } from 'framer-motion'
import useFormatDate from '../hooks/useFormatDate'
import Radio from './Radio'
import FlightDetailsModal from './FlightDetailsModal'
import FlightOverviewBodyMobile from './FlightOverviewBodyMobile'

const FlightOverviewMobile = ({
    id,
    stops,
    origin,
    carrier,
    segments,
    duration,
    hasOptions,
    originCity,
    isSelected,
    destination,
    arrivalTime,
    departureTime,
    baggageAllowed,
    destinationCity,
    onChangeAirRoute,
    landsDifferentDay,
}) => {
    const format = useFormatDate()
    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false)

    return (
        <div className="lg:hidden">
            <div className="flex items-center gap-x-4">
                {hasOptions ? (
                    <AnimatePresence>
                        <motion.div
                            initial={{ width: 0, opacity: 0 }}
                            animate={{ width: 'auto', opacity: 1 }}
                        >
                            <Radio
                                value={isSelected}
                                className="focus:ring-transparent"
                                onChange={() => onChangeAirRoute(id)}
                            />
                        </motion.div>
                    </AnimatePresence>
                ) : null}
                <div className="flex-1">
                    <div className="flex justify-between items-center text-sm text-gray-600 mb-4">
                        <div className="flex items-center">
                            <div className="relative w-5 h-5 bg-gray-100">
                                {carrier?.imageSquareUrl && (
                                    <img
                                        alt={carrier.name}
                                        src={carrier.imageSquareUrl}
                                        className="absolute inset-0 object-cover h-full w-full"
                                    />
                                )}
                            </div>
                            <span className="ml-2">
                                {carrier.name}
                            </span>
                        </div>
                        {format(new Date(departureTime), 'dd/MM/yyyy')}
                    </div>
                    <div className="flex justify-between items-center">
                        <FlightOverviewBodyMobile
                            stops={stops}
                            origin={origin}
                            carrier={carrier}
                            duration={duration}
                            originCity={originCity}
                            arrivalTime={arrivalTime}
                            destination={destination}
                            departureTime={departureTime}
                            destinationCity={destinationCity}
                        />
                        <button
                            type="button"
                            className="text-blue-bright"
                            onClick={() => setIsMoreDetailsOpen(true)}
                            data-testid="FlightOverviewMoreDetailsButtonMobile"
                        >
                            <Trans id="Details" />
                        </button>
                    </div>
                </div>
            </div>
            {landsDifferentDay && (
                <p className="ml-6 mt-1 text-primary">
                    <Trans id="Arrival" />
                    {' '}
                    {format(new Date(arrivalTime), 'dd.MM.yy')}
                </p>
            )}
            {isMoreDetailsOpen && (
                <FlightDetailsModal
                    segments={segments}
                    departureTime={departureTime}
                    testId="FlightOverviewMoreDetailsMobile"
                    onClose={() => setIsMoreDetailsOpen(false)}
                    baggageAllowed={baggageAllowed}
                />
            )}
        </div>
    )
}

export default FlightOverviewMobile
