import React from 'react'
import { Trans } from '@lingui/react'
import { formatPriceWithoutSymbol, getPersonPriceFromCharge } from '../../../util/prices'
import Icon from '../../utils/Icon'
import Button, { SIZE_LARGE, VARIANT_BLUE_FILLED } from '../../utils/Button'
import FlightEconomicsPriceDetails from './FlightEconomicsPriceDetails'

export function getBaggageText(hasAddon, baggageAllowed) {
    if (baggageAllowed) {
        return <Trans id="Luggage included" />
    }
    if (hasAddon) {
        return <Trans id="Luggage possible" />
    }
    return <Trans id="Luggage excluded" />
}

const FlightEconomicsDesktopDeprecated = ({
    charge,
    hasAddon,
    isLoading,
    isDisabled,
    baggageAllowed,
    isAlmostSoldOut,
    onCreateBookingFlight,
}) => (
    <div className="flex-col justify-between py-5 px-4 border-l border-gray-100 hidden lg:flex xl:min-w-[10.7rem]">
        <div className="text-center mb-3">
            {isAlmostSoldOut ? (
                <div className="text-sm text-primary mb-4 font-medium">
                    <Trans id="Last places!" />
                </div>
            ) : null}
            <p className="text-primary text-2xl font-semibold">
                <span className="text-lg">&euro;</span>
                <span data-testid="FlightChargeDesktop">
                    {formatPriceWithoutSymbol(Math.floor(getPersonPriceFromCharge(charge)))}
                </span>
            </p>
            <p className="mb-3 text-sm text-gray-600">
                <Trans id="Per person" />
            </p>
            <Button
                size={SIZE_LARGE}
                isLoading={isLoading}
                disabled={isDisabled}
                variant={VARIANT_BLUE_FILLED}
                onClick={onCreateBookingFlight}
                className="w-full uppercase font-semibold"
                data-testid="FlightEconomicsDesktopBookButton"
            >
                <Trans id="Book now" />
            </Button>
        </div>
        <div className="flex flex-col items-center text-center">
            <Icon
                size="small"
                color="blue-bright"
                name={hasAddon || baggageAllowed ? 'bag' : 'bag-slash'}
            />
            <p className="text-sm text-blue-bright mb-2">
                {getBaggageText(hasAddon, baggageAllowed)}
            </p>
            <FlightEconomicsPriceDetails charge={charge} />
        </div>
    </div>
)

export default FlightEconomicsDesktopDeprecated
