import { whereOriginalProductId } from '@connections/utils'

export const getActivitiesPrice = (activities, selectedActivities) => (
    activities
        // Non alternative activities are included
        ?.filter(({ productId, isAlternative }) => isAlternative && selectedActivities.some(whereOriginalProductId(productId)))
        ?.reduce((totalPrice, activity) => totalPrice + activity.priceLevels.reduce((dateListPrice, priceLevel) => {
            const dateListItem = priceLevel.dateList.find((dl) => (
                selectedActivities.some(({ dateProductId }) => dateProductId === dl.productId)
            ))
            return dateListItem ? dateListPrice + dateListItem.price.totalPrice : dateListPrice
        }, 0), 0)
)
