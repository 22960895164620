import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { FLIGHT_MODE_MULTI_CITY } from '@connections/constants'
import { variant as createVariant } from '../../util/index'
import Icon from './Icon'
import Radio from './Radio'
import SegmentDetails from './SegmentDetails'
import FlightOverviewHeading from './FlightOverviewHeading'
import SegmentLayoverDivider from './SegmentLayoverDivider'
import FlightOverviewTimingDeprecated from './FlightOverviewTimingDeprecated'

export const VARIANT_DEFAULT = 'default'
export const VARIANT_PADDED_LARGE = 'padded-large'

const FlightOverviewDesktopDeprecated = ({
    id,
    mode,
    stops,
    index,
    origin,
    carrier,
    segments,
    duration,
    isLoading,
    className,
    isSelected,
    originCity,
    hasOptions,
    destination,
    arrivalTime,
    airRouteType,
    departureTime,
    baggageAllowed,
    destinationCity,
    amountOfFlights,
    onChangeAirRoute,
    landsDifferentDay,
    variant = VARIANT_DEFAULT,
}) => {
    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false)
    const isFirstFlight = index === 0
    const isLastFlight = index === amountOfFlights - 1

    return (
        <div className={clsx(
            'hidden lg:block transition-colors duration-300',
            className,
            createVariant(variant, {
                [VARIANT_DEFAULT]: 'px-6',
                [VARIANT_PADDED_LARGE]: 'px-12',
            }),
            isLastFlight && createVariant(variant, {
                [VARIANT_DEFAULT]: 'py-6',
                [VARIANT_PADDED_LARGE]: 'pb-10 pt-6',
            }),
            isFirstFlight && createVariant(variant, {
                [VARIANT_DEFAULT]: 'pt-6 pb-3',
                [VARIANT_PADDED_LARGE]: 'pt-10 pb-6',
            }),
            isMoreDetailsOpen && 'bg-gray-50',
            !isLastFlight && !isFirstFlight && createVariant(variant, {
                [VARIANT_DEFAULT]: 'py-3',
                [VARIANT_PADDED_LARGE]: 'py-6',
            }),
        )}
        >
            {isFirstFlight && (
                <FlightOverviewHeading
                    departureTime={departureTime}
                    airRouteType={mode === FLIGHT_MODE_MULTI_CITY ? '' : airRouteType}
                />
            )}
            <div className="flex justify-center gap-4">
                <div className="flex flex-1 gap-4">
                    <div className="w-4">
                        {hasOptions ? (
                            <AnimatePresence className="w-4">
                                <motion.div
                                    className="mt-2"
                                    initial={{ width: 0, opacity: 0 }}
                                    animate={{ width: 'auto', opacity: 1 }}
                                >
                                    <Radio
                                        value={isSelected}
                                        onChange={() => onChangeAirRoute(id)}
                                    />
                                </motion.div>
                            </AnimatePresence>
                        ) : null}
                    </div>
                    <div className="flex flex-col items-center w-12">
                        <div className="w-10 h-10">
                            {carrier?.imageSquareUrl && (
                                <img
                                    alt={carrier.name}
                                    src={carrier.imageSquareUrl}
                                />
                            )}
                        </div>
                        <p className="text-sm text-center text-gray-400 mt-1">
                            {carrier.name}
                        </p>
                    </div>
                    <div className="w-32">
                        <p className="text-lg font-medium leading-tight mb-1">
                            {originCity.name}
                            {originCity.nameExtension && (
                                <span className="text-base block">
                                    {originCity.nameExtension}
                                </span>
                            )}
                        </p>
                        <p className="text-gray-600">
                            [{origin}]
                        </p>
                    </div>
                </div>
                <FlightOverviewTimingDeprecated
                    stops={stops}
                    duration={duration}
                    arrivalTime={arrivalTime}
                    departureTime={departureTime}
                    landsDifferentDay={landsDifferentDay}
                />
                <div className="flex flex-1 justify-end gap-4">
                    <div className="w-32">
                        <p className="text-lg font-medium leading-tight mb-1">
                            {destinationCity.name}
                            {destinationCity.nameExtension && (
                                <span className="text-sm block">
                                    {destinationCity.nameExtension}
                                </span>
                            )}
                        </p>
                        <p className="text-gray-600">
                            [{destination}]
                        </p>
                    </div>
                    <button
                        type="button"
                        className={clsx(
                            'flex items-center -mt-4',
                            isLoading && 'cursor-wait',
                        )}
                        data-testid="FlightOverviewMoreDetailsButtonDesktop"
                        onClick={() => !isLoading && setIsMoreDetailsOpen(!isMoreDetailsOpen)}
                    >
                        <motion.div
                            variants={{
                                open: { rotate: 180 },
                                closed: { rotate: 0 },
                            }}
                            animate={isMoreDetailsOpen ? 'open' : 'closed'}
                        >
                            <Icon
                                size="smaller"
                                name="arrow-down"
                                color="blue-bright"
                            />
                        </motion.div>
                        <p className="text-sm underline text-blue-bright whitespace-nowrap">
                            {isMoreDetailsOpen
                                ? <Trans id="Less details" />
                                : <Trans id="More details" />}
                        </p>
                    </button>
                </div>
            </div>
            <AnimatePresence initial={false}>
                {isMoreDetailsOpen && (
                    <motion.div
                        animate="open"
                        exit="collapsed"
                        initial="collapsed"
                        className="overflow-hidden"
                        transition={{ duration: 0.3 }}
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        data-testid="FlightOverviewMoreDetailsDesktop"
                    >
                        {segments.map((segment, segmentIndex) => {
                            const nextSegment = segments[segmentIndex + 1]
                            return (
                                <div key={segment.id}>
                                    <div className="bg-white rounded border border-gray-100 mt-4">
                                        <SegmentDetails
                                            segment={segment}
                                            baggageAllowed={baggageAllowed}
                                        />
                                    </div>
                                    {nextSegment && (
                                        <SegmentLayoverDivider
                                            arrivalTime={segment.arrivalTime}
                                            departureTime={nextSegment.departureTime}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default FlightOverviewDesktopDeprecated
