import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { motion } from 'framer-motion'
import useFlightProps from '../../hooks/useFlightProps'
import CheckoutInfoCard from '../../utils/CheckoutInfoCard'
import FlightOverviewMobile from '../../utils/FlightOverviewMobile'
import Button, { SIZE_SMALLEST, VARIANT_BLUE_OUTLINED } from '../../utils/Button'
import FlightEconomicsMobile from './FlightEconomicsMobile'
import FlightSearchPageResultCardLabels from './FlightSearchPageResultCardLabels'

const FlightSearchPageResultCardMobile = ({
    mode,
    alerts,
    charge,
    hasAddon,
    onExpand,
    hasLabel,
    isFastest,
    isCheapest,
    isRecommended,
    collapsedState,
    baggageAllowed,
    onChangeAirRoute,
    selectedAirRoutes,
    airRouteCollections,
    onCreateBookingFlight,
}) => {
    const { getFlightPropsFromAirRoute } = useFlightProps()
    const cardHasOptions = airRouteCollections.some(((airRouteCollection) => airRouteCollection.length > 1))

    return (
        <div
            className={clsx(
                'border border-gray-100 rounded-md mb-4 bg-white lg:hidden',
                alerts.length > 0 && 'rounded-b-none',
            )}
        >
            <FlightSearchPageResultCardLabels
                hasLabel={hasLabel}
                className="mt-4 mx-4"
                isFastest={isFastest}
                isCheapest={isCheapest}
                isRecommended={isRecommended}
            />
            {airRouteCollections.map((airRouteCollection, collectionIndex) => {
                const { isCollapsed, collapsedCount } = collapsedState[collectionIndex]

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={collectionIndex}>
                        {collectionIndex !== 0 && (
                            <div className="h-px border-b border-gray-200 border-dashed w-11/12 mx-auto" />
                        )}
                        <div className="flex flex-col gap-y-6 p-4">
                            {airRouteCollection.map((airRoute, index, array) => (
                                <motion.div
                                    key={airRoute.id}
                                    className="overflow-hidden"
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    transition={{ duration: isCollapsed ? 0.15 : 0 }}
                                >
                                    <FlightOverviewMobile
                                        mode={mode}
                                        index={index}
                                        hasOptions={array.length > 1}
                                        amountOfFlights={array.length}
                                        baggageAllowed={baggageAllowed}
                                        cardHasOptions={cardHasOptions}
                                        isSelected={selectedAirRoutes[collectionIndex] === airRoute.id}
                                        onChangeAirRoute={(value) => onChangeAirRoute(value, collectionIndex)}
                                        {...getFlightPropsFromAirRoute(airRoute)}
                                    />
                                </motion.div>
                            ))}
                            {isCollapsed ? (
                                <Button
                                    iconSize="smallest"
                                    size={SIZE_SMALLEST}
                                    iconBeforeName="clock"
                                    className="w-fit mx-auto -mt-3"
                                    variant={VARIANT_BLUE_OUTLINED}
                                    onClick={() => onExpand(collectionIndex)}
                                >
                                    <Trans id="Show other flight times" />
                                    {' '}
                                    ({collapsedCount})
                                </Button>
                            ) : null}
                        </div>
                    </div>
                )
            })}
            <FlightEconomicsMobile
                charge={charge}
                hasAddon={hasAddon}
                baggageAllowed={baggageAllowed}
                onCreateBookingFlight={onCreateBookingFlight}
            />
            {alerts.map(({ content }) => {
                const [layout] = content.layout
                return (
                    <CheckoutInfoCard
                        key={content._uid}
                        title={layout.title}
                        richText={layout.text}
                        iconName={layout.icon}
                        variant={layout.variant}
                        className="rounded-t-none"
                        colorVariant={layout.colorVariant}
                    />
                )
            })}
        </div>
    )
}

export default FlightSearchPageResultCardMobile
